var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('vue-toastr',{ref:"mytoast"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"validationCustom01"}},[_vm._v("Channel")]),_c('multiselect',{staticClass:"m-2",class:{
                      'is-invalid': _vm.submitted && !_vm.channel,
                    },attrs:{"options":_vm.channelOption},model:{value:(_vm.channel),callback:function ($$v) {_vm.channel=$$v},expression:"channel"}})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"validationCustom02"}},[_vm._v("Recipient")]),_c('multiselect',{staticClass:"m-2",class:{
                      'is-invalid': _vm.submitted && !_vm.recipient,
                    },attrs:{"options":_vm.recipientOption},model:{value:(_vm.recipient),callback:function ($$v) {_vm.recipient=$$v},expression:"recipient"}})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"validationCustom03"}},[_vm._v("Subject")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.subject),expression:"subject"}],staticClass:"form-control m-2",class:{
                      'is-invalid': _vm.submitted && !_vm.subject,
                    },attrs:{"id":"validationCustom03","type":"text","placeholder":"Subject..."},domProps:{"value":(_vm.subject)},on:{"input":function($event){if($event.target.composing){ return; }_vm.subject=$event.target.value}}})]),_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"validationCustom04"}},[_vm._v("Message")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],staticClass:"form-control m-2",class:{
                      'is-invalid': _vm.submitted && !_vm.message,
                    },attrs:{"name":"message","id":"","cols":"30","rows":"10"},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.message=$event.target.value}}})])]),_c('button',{staticClass:"btn btn-primary m-2",attrs:{"disabled":_vm.submitted},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v(" Submit form")])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }